$().ready(function(){
	setupSlideMenu();
	stickyNav();
	animateWords();
	eventScroller();
	homeExpands();
	if ($("form").length){
		$("form").each(function(){
			$(this).addClass("floatlabel-active").validate();
			$(this).find(".js-float-wrap").FloatLabel();
		});
	}
	$(".gallery .expand").click(function(){
		$(this).next(".kmsgallery").children("a").first().click();
	});
	$("#map-click").click(function(){
		// shield the map on mobile for easy scrolling
		$(this).hide();
	});
});

export function setupSlideMenu(){
	$("#mobile-menu, .slide-menu h3").click(function(){
		$("body").toggleClass("slide-menu-active");
		// update icon title
		if ($("body").hasClass("slide-menu-active")){
			$("#mobile-menu").attr("title", "Close");
		} else {
			$("#mobile-menu").attr("title", "Menu");
		}
	});
	$(".slide-menu").on("click",".expand",function(e) {
		e.stopPropagation();
		$(this).closest("li").toggleClass("expand-active");
	});
	$(".slide-menu li").each(function(index, element) {
		var $el = $(element);
		if ($el.find("ul").length > 0) {
			var expander = $("<span class=\"expand\"><i class=\"nav-arrow\"></i></span>");
			$el.append(expander);
			expander.click(function(e) {
				e.stopPropagation();
				$el.toggleClass("expand-active");
			});
		}
	});
}

export function stickyNav(){
	var stickyPos = $("header.top").outerHeight() - $(".header-menu").height();
	$(document).scroll(function(){
		if ($(window).scrollTop() >= stickyPos){
			$("header").addClass("sticky");
		} else if ($(window).scrollTop() < stickyPos){
			$("header").removeClass("sticky");
		}
	});
}

export function animateWords(){
	if ($(".feature").length > 0){
		setInterval(function(){swapWords();}, 1000);
	}
	function swapWords() {
		var $frame = $(".frame");
		$frame.each(function(i, el){
			var $current = $(el).find("h2.active");
			var $next = $(el).find("h2.active").next();
			var $first = $(el).find("h2").first();
			var $last = $(el).find("h2").last();

			if (! $last.hasClass("active")){
				$current.removeClass("active");
				$next.addClass("active");
			} else {
				$current.removeClass("active");
				$first.addClass("active");
			}
		});
	}
}

export function checkScrollerSize($wrap){
	$wrap.each(function(){
		var $scroller = $(this).find(".scroller");
		if ($scroller.scrollLeft() == 0){
			$(this).find("#prev").hide();
		} else {
			$(this).find("#prev").show();
		}
		if ($scroller.scrollLeft() + $scroller.innerWidth() >= $scroller[0].scrollWidth) {
			$(this).find("#next").hide();
		} else {
			$(this).find("#next").show();
		}
	});
}
export function eventScroller(){
	if ($(".scroller .event").length > 1){
		$(".scroll-nav").show();
		var $wrap = $(".scroll-wrap");
		var $scroller = $wrap.find(".scroller");

		$wrap.each(function() {
			var $next = $wrap.find("#next");
			var $prev = $wrap.find("#prev");
			var $tiles = $(".scroll-nav .tile");

			function slide(offset){
				var $distance = $scroller.innerWidth();
				var $before = $scroller.find(".event.active").prev();
				var $after = $scroller.find(".event.active").next();
				if (!$scroller.data("animating")){
					$scroller.data("animating", true);
					if(offset < 0){ //back
						if ($scroller.scrollLeft() > 0){
							var newPos = $scroller.scrollLeft() - $distance;
							$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
								checkScrollerSize($wrap);
								$scroller.data("animating", false);
							});
							// set active
							$scroller.find(".event.active").removeClass("active");
							$before.addClass("active");
							var $id = $before.data("id");
							$tiles.removeClass("active");
							$("#"+ $id).addClass("active");
						} else $prev.hide();
					} else if (offset == 1) { //fwd
						if ($scroller.scrollLeft() + $scroller.innerWidth() < $scroller[0].scrollWidth){
							newPos = $scroller.scrollLeft() + $distance;
							$scroller.stop().animate({scrollLeft:newPos},500,"swing", function(){
								checkScrollerSize($wrap);
								$scroller.data("animating", false);
							});
							// set active
							$scroller.find(".event.active").removeClass("active");
							$after.addClass("active");
							$id = $after.data("id");
							$tiles.removeClass("active");
							$("#"+ $id).addClass("active");
						} else $next.hide();
					} else { //tile click id
						$id = offset;
						var $new = $scroller.find(".event[data-id="+$id+"]");
						var newLeft = $new.offset().left - $scroller.offset().left + $scroller.scrollLeft();

						$tiles.removeClass("active");
						$("#"+ $id).addClass("active"); //tile
						$scroller.find(".event.active").removeClass("active");
						$new.addClass("active");
						$scroller.stop().animate({scrollLeft:newLeft},500,"swing", function(){
							checkScrollerSize($wrap);
							$scroller.data("animating", false);
						});
					}
				}
			}
			// click functions
			$prev.on("click touch",function(){
				slide(-1);
			});
			$next.on("click touch",function(){
				slide(1);
			});
			$tiles.each(function(index, element){
				var $tile = $(element);
				$tile.on("click touch", function(){
					var id = $(this).attr("id");
					slide(id);
				});
			});
		});
		$(window).resize(function(){
			var $distance = $scroller.innerWidth();
			var index = Math.round($scroller.scrollLeft()/$distance);
			$scroller.scrollLeft(index*$scroller.innerWidth());
			checkScrollerSize($wrap);
		});
		checkScrollerSize($wrap);
	}
}

export function homeExpands(){
	$(".feature a.expand, div.expand-content .close-bar").click(function(e){
		e.preventDefault();
		var is_expand = ($(this).data("id"))? true : false;
		$(this).parents("div.feature").toggleClass("active");
		if(is_expand){
			var id = $(this).data("id");
			var pos = $(id).offset().top - 150;
			$("html, body").animate({scrollTop:pos},500);
		}
	});
}
